import React, { useEffect, useMemo, useState } from "react";
import { leaveBalanceTitle } from "../../Constant/title";
import { RiSearchLine } from "react-icons/ri";
import { notFound, Search, Submit } from "../../Constant";
import * as yup from "yup";
import {
  checkStatusCodeSuccess,
  errHandler,
  extractDate,
  getFinancialYear,
  getItem,
  getLocalItem,
  handleResponse,
  isUserProfile,
  moduleName,
  rendorBackButton,
} from "../../Constant/common";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../BaseComponents/BaseLoader";
import { toast } from "react-toastify";
import {
  editLeaveBalanceApi,
  leaveBalanceHistoryApi,
  staffLeaveBalanceApi,
} from "../../Api/leaveBalanceApi";
import { leaveBalanceEnums } from "../../Constant/HR/leaveBalance";
import { useFormik } from "formik";
import BaseButton from "../../BaseComponents/BaseButton";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import BaseInput from "../../BaseComponents/BaseInput";
import { HR } from "../../Constant/hrLayout";
import TableContainer from "../../BaseComponents/BaseTable";
import { fractionNumberRegex, validationMessages } from "../../Constant/validation";

const LeaveBalance = () => {
  document.title = leaveBalanceTitle;
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [totalRemainingLeaves, setTotalRemainingLeaves] = useState(0);
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [searchLeaveHistory, setSearchLeaveHistory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [customPageSize, setCustomPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const { staffId } = useParams();
  const currentDate = new Date();
  const financialYear = getFinancialYear(currentDate);
  const isProfile = JSON.parse(getItem(isUserProfile) || false);
  const profileId = getLocalItem("id");
  const finalId = isProfile ? profileId : staffId;
  const navigate = useNavigate();
  const permission = JSON.parse(getLocalItem("permissions"));
  const hasLeaveBalance = permission?.some(
    (perm) => perm.parent_module_name === HR && perm.module_name === moduleName.LeaveBalance
  );
  
  const fetchLeaveData = () => {
    setLoader(true);
    const payload = {};
    staffLeaveBalanceApi(finalId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res?.statusCode)) {
          const leaves = res?.data?.filter((item) => {
            return item?.leave_category_name !== leaveBalanceEnums.unpaidLeave;
          });
          formik.setValues({
            leaves: leaves?.map((item) => {
              return {
                leave_template_id: item?.leave_id,
                leave_category_id: item?.leave_category_id,
                leave_category_name: item?.leave_category_name,
                remaining_leave: item?.remaining_leave,
              };
            }),
          });
          fetchLeaveBalanceHistory();
        } else {
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
  };
  
  useEffect(() => {
    fetchLeaveData();
  }, []);
  
  const fetchLeaveBalanceHistory = () => {
    setLoader(true);
    const payload = {
      staffId: Number(finalId),
      order: [columnName, sortOrder],
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    leaveBalanceHistoryApi(payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res)) {
          setLeaveHistory(res?.data?.leaveBalanceHistory);
          setTotalPages(res?.data?.totalPages);
          setTotalRecords(res?.data?.totalRecordsCount);
          setTotalNumberOfRows(res?.data?.numberOfRows);
          setCurrentPage(res?.data?.currentPage);
        } else {
          setLeaveHistory([]);
        }
      })
      .catch((err) => {
        setLeaveHistory([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
    })
  }

  const fetchSearchHistoryData = () => {
    setLoader(true);
    const payload = {
      staffId: Number(finalId),
      order: [columnName, sortOrder],
      search: searchValue,
      pageNumber: currentPage,
      pageSize: customPageSize,
    };

    leaveBalanceHistoryApi(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          setSearchLeaveHistory(resp?.data?.leaveBalanceHistory);
          setTotalRecords(resp.data.totalRecordsCount);
          setTotalPages(resp?.data?.totalPages);
          setTotalNumberOfRows(resp?.data?.numberOfRows);
          setCurrentPage(resp?.data?.currentPage);
        }else{
          setSearchLeaveHistory([]);
        }
      })
      .catch((err) => {
        setSearchLeaveHistory([]);
        return err;
      })
      .finally(() => {
        setLoader(false);
      });
    };
  
  useEffect(() => {
    if(searchValue === "")
    {
      setCurrentPage(1)
    }
  },[searchValue])

  useEffect(() => {
    if(searchValue) {
      fetchSearchHistoryData();
    } else {
      fetchLeaveBalanceHistory();
    }
  }, [currentPage, customPageSize, columnName, sortOrder, searchValue]);
  
  const handleSearchValueChange = (value) => {
    if (value !== searchValue) {
      setCurrentPage(1);
      setSearchValue(value);
    }
  };
  
  const handleFetchData = (page) => {
    setCurrentPage(page);
  };
  
  const handleFetchSorting = (page, id, order) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };
  
  const historyColumn = useMemo(
    () => [
      {
        header: leaveBalanceEnums.SrNo,
        accessorKey: "serial_number",
        cell: (cell) => cell.row.index + 1,
        enableColumnFilter: false,
      },
      {
        header: leaveBalanceEnums.leaveCategory,
        accessorKey: "leave_category_name",
        enableColumnFilter: false,
      },
      {
        header: leaveBalanceEnums.previousCount,
        accessorKey: "remaining_leave_count",
        enableColumnFilter: false,
      },
      {
        header: leaveBalanceEnums.leaveCount,
        accessorKey: "leave_count",
        enableColumnFilter: false,
      },
      {
        header: leaveBalanceEnums.finalCount,
        accessorKey: "total_leave",
        enableColumnFilter: false,
      },
      {
        header: leaveBalanceEnums.addedBy,
        accessorKey: "addedBy.name",
        enableColumnFilter: false,
      },
      {
        header: leaveBalanceEnums.updatedOn,
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell) => {
          return (
            extractDate(cell.row.original?.date) ?? handleResponse.nullData
          );
        },
      },
    ],
    []
  );
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object({
      leaves: yup.array().of(
        yup.object({
          leave_count: yup
            .string()
            .matches(fractionNumberRegex, validationMessages.format(leaveBalanceEnums.leaveCount)),
        })
      ),
    }),
    initialValues: {
      leaves: [
        {
          leave_template_id:null,
          leave_category_id: null,
          leave_category_name: null,
          remaining_leave: null,
          leave_count: null,
          total_leave:null,
        },
      ],
    },
    onSubmit: (values, { resetForm }) => {
      const payload = {
        leaves: values.leaves
          ?.filter((item) => item?.leave_count && Number(item?.leave_count) !== 0)
          .map((item) => ({
            leave_template_id: Number(item?.leave_template_id),
            leave_category_id: Number(item?.leave_category_id),
            leave_count: Number(item?.leave_count || 0),
          })), 
      };
      handleSubmit(payload);
    },
  });

  useEffect(() => {
    const total = formik.values.leaves?.reduce(
      (sum, leave) => sum + (Number(leave?.remaining_leave) || 0),
      0
    );
    setTotalRemainingLeaves(total);
  }, [formik.values.leaves]);
  
  const handleSubmit = (payload) => {
    setBtnLoader(true);
    editLeaveBalanceApi(finalId, payload)
      .then((res) => {
        if (checkStatusCodeSuccess(res?.statusCode)) {
          toast.success(res?.message);
          fetchLeaveData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        errHandler(err);
      })
      .finally(() => {
        setBtnLoader(false);
      });
  };


  return (
    <>
      <div className="page-content">
        {loader && <Spinner attrSpinner={{ className: "loader-2" }} />}
        <div className="d-flex justify-content-end mb-2">
          {rendorBackButton(() => isProfile ? navigate("/profile") : navigate(`/staffProfile/${staffId}`))}
        </div>
        <div className="leave-card card p-2">
          <div className="d-flex gap-2 px-3">
            <h5>{leaveBalanceEnums.leaveBalance}</h5>
            <span>{financialYear}</span>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className=" px-3">
                {leaveBalanceEnums.balanceLeave}
                <div className="f-w-600">{totalRemainingLeaves}</div>
              </div>
            </div>
            <div className="d-flex"></div>
          </div>
        </div>

        <div className="leave-balance-page">
          <form onSubmit={formik.handleSubmit}>
            <div className="card">
              <div className="card-body p-4">
                {formik.values.leaves?.length === 0 ? (
                  <div className="container-fluid">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="py-4 text-center">
                        <div>
                          <RiSearchLine className="fs-2" />
                        </div>
                        <div className="mt-4">
                          <h5>{notFound.dataNotFound}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ul className="list-group list-group-flush">
                    {formik?.values?.leaves?.map((leave, index) => {
                      return (
                        <li
                          key={index}
                          className="list-group-item border-Bottom"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-3">
                              <div className="form-group mb-2">
                                <BaseInput
                                  type="text"
                                  label={leaveBalanceEnums.leaveCategory}
                                  className="form-control"
                                  name={`leaves.${index}.leave_category_name`}
                                  placeholder={PlaceHolderFormat(leaveBalanceEnums.leaveCategory)}
                                  value={leave.leave_category_name}
                                  handleChange={formik.handleChange}
                                  handleBlur={formik.handleBlur}
                                  error={formik.errors?.leaves?.[index]?.leave_category_name}
                                  touched={formik.touched?.leaves?.[index]?.leave_category_name}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group mb-2">
                                <BaseInput
                                  type="text"
                                  label={leaveBalanceEnums.remainingLeave}
                                  className="form-control"
                                  name={`leaves.${index}.remaining_leave`}
                                  placeholder={PlaceHolderFormat(leaveBalanceEnums.remainingLeave)}
                                  value={formik.values.leaves?.[index].remaining_leave}
                                  handleChange={formik.handleChange}
                                  handleBlur={formik.handleBlur}
                                  error={formik.errors?.leaves?.[index]?.remaining_leave}
                                  touched={formik.touched?.leaves?.[index]?.remaining_leave}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group mb-2">
                                <BaseInput
                                  type="text"
                                  label={leaveBalanceEnums.leaveCount}
                                  className="form-control"
                                  name={`leaves.${index}.leave_count`}
                                  placeholder={PlaceHolderFormat(leaveBalanceEnums.leaveCount)}
                                  value={formik.values.leaves?.[index].leave_count}
                                  handleChange={(e) => {
                                    if (e.target.value <= 10) {
                                      formik.handleChange(e);
                                    }
                                  }
                                  }
                                  handleBlur={formik.handleBlur}
                                  error={formik.errors?.leaves?.[index]?.leave_count}
                                  touched={formik.touched?.leaves?.[index]?.leave_count}
                                  disabled={!hasLeaveBalance}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group mb-2">
                                <BaseInput
                                  type="number"
                                  label={leaveBalanceEnums.totalLeave}
                                  className="form-control"
                                  name={`leaves.${index}.total_leave`}
                                  placeholder={PlaceHolderFormat(leaveBalanceEnums.leaveCount)}
                                  value={Number(formik.values.leaves?.[index].leave_count || 0)+Number(formik.values.leaves?.[index].remaining_leave || 0)}
                                  handleChange={formik.handleChange}
                                  handleBlur={formik.handleBlur}
                                  error={formik.errors?.leaves?.[index]?.total_leave}
                                  touched={formik.touched?.leaves?.[index]?.total_leave}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            <div className="mb-2 px-4">
              <div className="col-12 d-flex justify-content-end">
                <BaseButton
                  type="submit"
                  loader={btnLoader}
                  className="btn btn-success"
                >
                  {Submit}
                </BaseButton>
              </div>
            </div>
            </div>
          </form>
        </div>
        <h5 className="mx-2">{leaveBalanceEnums.leaveBalanceHistory}</h5>
        <div className="card mx-2">
        <div className="card-body">
          {leaveHistory && leaveHistory?.length > 0 && (
            <TableContainer
              totalPages={totalPages}
              totalRecords={totalRecords}
              totalNumberOfRows={totalNumberOfRows}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              fetchData={handleFetchData}
              customPageSize={customPageSize}
              setCustomPageSize={setCustomPageSize}
              fetchSortingData={handleFetchSorting}
              columns={historyColumn}
              shouldLineBreak={false}
              data={searchValue ? searchLeaveHistory : leaveHistory || []}
              manualPagination={true}
              SearchPlaceholder={Search}
              onSearch={handleSearchValueChange}
              isGlobalFilter={true}
              tableClass="table table-bordered"
              manualFiltering={true}
            />
          )}
          {leaveHistory?.length < 1 && (
            <div className="py-4 text-center card">
              <div>
                <RiSearchLine className="fs-2" />
              </div>
              <div className="mt-4">
                <h5>{notFound.dataNotFound}</h5>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveBalance;
