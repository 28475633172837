import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { getLocalItem, removeLocalItem } from "../Constant/common";
export const baseURL = process.env.REACT_APP_BASEURL;
export const BaseImageURL = process.env.REACT_APP_BASEIMAGEURL;
export const BaseExportURL = process.env.REACT_APP_BASEEXPORTURL;
export const BaseStaffURL = process.env.REACT_APP_BASESTAFFURL;

const handleUnauthorizedError = (instance) => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
        removeLocalItem("token");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
};

export const createAxiosInstance = (baseURL) => {
  const Bearer = "Bearer";
  const instance = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(async (config) => {
    if (config && config.headers) {
      const authToken = getLocalItem("token");
      if (authToken) {
        config.headers["Authorization"] = `${Bearer} ${authToken}`;
      }
    }
    return config;
  });
  handleUnauthorizedError(instance);
  return instance;
};

export const createNonAuthAxiosInstance = (
  baseURL,
  contentType = "application/json"
) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": contentType,
    },
  });
};

export const createAuthAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  instance.interceptors.request.use(async (config) => {
    if (config && config.headers) {
      const authToken = getLocalItem("token");
      if (authToken) {
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
    }
    return config;
  });
  handleUnauthorizedError(instance);
  return instance;
};

export const authData = createAxiosInstance(baseURL);
export const nonAuthData = createNonAuthAxiosInstance(baseURL);
export const multipartData = createNonAuthAxiosInstance(
  baseURL,
  "multipart/form-data"
);
export const multipartDataWithToken = createAuthAxiosInstance(baseURL);
