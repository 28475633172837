import React, { Fragment } from "react";
import "./App.css";
import Routers from "./Routes";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/customizer/CustomizerProvider";
import { addEvent, clearItem, LOG_OUT, Storage } from "./Constant/common";

function App() {
  addEvent(Storage, (event) => {
  if (event.key === LOG_OUT) {
    clearItem();
    window.location.href = '/login';
  }
  });
  return (
    <Fragment>
      <CustomizerProvider>
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>
      </CustomizerProvider>
    </Fragment>
  );
}
export default App;
