import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { PlaceHolderFormat } from "../../Constant/requireMessage";
import BaseInput from "../../BaseComponents/BaseInput/index";
import {
  Email,
  ForgotPassword,
  Password,
  SignIn,
  login,
  welcomeBack,
  Loading,
} from "../../Constant";
import { emailRegex, validationMessages } from "../../Constant/validation";
import { loginApi } from "../../Api/AuthApi";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";
import { Link, useNavigate } from "react-router-dom";
import BaseButton from "../../BaseComponents/BaseButton";
import {
  All,
  CENTER_IDS,
  CENTER_NAME,
  checkStatusCodeSuccess,
  DEFAULT_CENTER,
  getLocalItem,
  getSessionId,
  loginMessage,
  navigateToFirstModule,
  setLocalItem,
} from "../../Constant/common";
import { userRolePermissionApi } from "../../Api/roleApi";
import { centerApi } from "../../Api/common";

const Login = () => {
  document.title = login;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required(validationMessages.required(Email))
        .matches(emailRegex, validationMessages.format(Email)),
      password: yup.string().required(validationMessages.required(Password)),
    }),
    onSubmit: (values) => {
      setLoader(true);
      const payload = {
        email: values.email,
        password: values.password,
        platform: "web",
      };
      loginApi(payload)
        .then((resp) => {
          if (
            resp.statusCode === StatusCodes.ACCEPTED ||
            resp.statusCode === StatusCodes.OK ||
            resp.statusCode === StatusCodes.CREATED
          ) {
            setLocalItem("token", resp?.data?.token);
            getSessionId(resp?.data?.token);
            if (getLocalItem("role")) {
              setLoader(true);
              userRolePermissionApi({ role_name: getLocalItem("role") })
                .then((resp) => {
                  if (checkStatusCodeSuccess(resp.statusCode)) {
                    toast.success(loginMessage.success);
                    const permission = resp?.data?.sort((a, b) => {
                      if (a.module_name < b.module_name) return -1;
                      return 0;
                    });
                    setLocalItem("permissions", JSON.stringify(permission));
                    centerApi()
                      .then((resp) => {
                        if (checkStatusCodeSuccess(resp.statusCode)) {
                          const centers = resp?.data?.map((item) => ({
                            label: String(item?.center_name),
                            value: String(item?.id),
                          }));
                          const centerIds = getLocalItem(CENTER_IDS);
                          if (centerIds !== null) {
                            const parsedCenterIds = JSON.parse(centerIds);
                            if (
                              Array.isArray(parsedCenterIds) &&
                              parsedCenterIds.length > 0
                            ) {
                              const selected_center =
                                parsedCenterIds?.length > 1
                                  ? parsedCenterIds.at(0) !== All
                                    ? parsedCenterIds.at(0)
                                    : null
                                  : parsedCenterIds[0];
                              setLocalItem(DEFAULT_CENTER, selected_center);
                              const selectedCenterName = centers.find(
                                (item) => item.value === String(selected_center)
                              )?.label;
                              setLocalItem(CENTER_NAME, selectedCenterName);
                            }
                          }
                        }
                      })
                      .catch((err) => {
                        return err;
                      })
                      .finally(() => {
                        setLoader(false);
                        navigateToFirstModule(navigate);
                      });
                  }
                })
                .catch(() => {
                  toast.error(loginMessage.accessDenied);
                })
                .finally(() => {
                  setLoader(false);
                });
            }
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    },
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-7">
          <div className="bg-img-cover"></div>
        </div>
        <div className="col-xl-5 p-0">
          <div className="login-card">
            <form
              className="theme-form login-form"
              onSubmit={loginForm.handleSubmit}
            >
              <div className="login-header text-center mb-2">
                <h4>{login}</h4>
                <span className="text-gray fw-normal">{welcomeBack}</span>
              </div>
              <div className="login-social-title">
                <h5 className="text-gray">{SignIn} With Email</h5>
              </div>
              <div className="form-group">
                <label>{Email}</label>
                <div className="input-group">
                  <BaseInput
                    name="email"
                    type="email"
                    icon={<i className="icon-email" />}
                    placeholder={PlaceHolderFormat(Email)}
                    handleChange={loginForm.handleChange}
                    handleBlur={loginForm.handleBlur}
                    value={loginForm.values.email}
                    touched={loginForm.touched.email}
                    error={loginForm.errors.email}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>{Password}</label>
                <BaseInput
                  className="form-control"
                  name="password"
                  type="password"
                  icon={<i className="icon-lock" />}
                  placeholder={PlaceHolderFormat(Password)}
                  handleChange={loginForm.handleChange}
                  handleBlur={loginForm.handleBlur}
                  value={loginForm.values.password}
                  touched={loginForm.touched.password}
                  error={loginForm.errors.password}
                />
              </div>
              <div className="form-group d-flex justify-content-end">
                <Link to={"/forgotPassword"} className="link">
                  {ForgotPassword}
                </Link>
              </div>
              <div className="form-group">
                <BaseButton
                  className="btn btn-pill btn-block d-flex align-items-center"
                  type="submit"
                  color="primary"
                  disabled={loader}
                  loader={loader}
                  children={loader ? Loading : SignIn}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
