import React, { createContext, useContext, useEffect, useState } from "react";
import { All, DEFAULT_CENTER, getItem, getLocalItem, Null, SELECTED_CENTER_ID, Storage } from "../../Constant/common";

const SelectedCenterContext = createContext();

export const SelectedCenterProvider = ({ children }) => {
  const [selectedCenter, setSelectedCenter] = useState(() => {
    const centerID = getItem(SELECTED_CENTER_ID) ? getItem(SELECTED_CENTER_ID) !== Null ? getItem(SELECTED_CENTER_ID) : getLocalItem(DEFAULT_CENTER) : getLocalItem(DEFAULT_CENTER);
    if (centerID && centerID !== All && centerID !== null) {
      const parsedCenterID = Number(centerID);
      return !isNaN(parsedCenterID) ? parsedCenterID : null;
    }
    return null;
  });

  const selectedCenterPayload = selectedCenter !== null ? { center_id: selectedCenter } : {};

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === SELECTED_CENTER_ID) {
        const newCenterID = event.newValue;
        setSelectedCenter((newCenterID === All || newCenterID === null) ? null : Number(newCenterID));
      }
    };
    window.addEventListener(Storage, handleStorageChange);
    return () => {
      window.removeEventListener(Storage, handleStorageChange);
    };
  }, []);

  return (
    <SelectedCenterContext.Provider
      value={{ selectedCenter, setSelectedCenter, selectedCenterPayload }}
    >
      {children}
    </SelectedCenterContext.Provider>
  );
};

export const useSelectedCenter = () => {
  return useContext(SelectedCenterContext);
};